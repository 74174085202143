<template>
  <div class="d-flex h-100 w-100">
    <div class="phModal-centered">
      <section :class="['phModal phModal_wrapper', classes]">
        <header
          v-if="$slots.header || !notClose"
          class="phModal_header"
        >
          <h3 class="phModal_title">
            <slot name="header" />
          </h3>
          <ui-icon
            v-if="!notClose"
            :icon="ICONS.cross"
            :color="GRAYSCALE.inkLight"
            hover
            @click="close"
          />
        </header>

        <div class="phModal_body">
          <slot name="body" />
        </div>

        <footer
          v-if="$slots.footer"
          class="phModal_footer"
        >
          <slot name="footer" />
        </footer>
      </section>
    </div>
  </div>
</template>

<script>

import ModalMixin from '@/mixins/Modal';

export default {
  mixins: [ModalMixin],
  props: {
    classes: {
      type: String,
      default: '',
    },
    notClose: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('close-modal');
      this.closeModalComponent();
    },
  },
};

</script>
