<template>
  <div class="phModal_backdrop LinkAccount">
    <NotificationListManager />
    <Modal not-close>
      <template slot="header">
        <span class="LinkAccount__title emobg-font-x-large">
          {{ $t('views.link_business_profile.title') }}
        </span>
      </template>

      <template slot="body">
        <h2 class="LinkAccount__subtitle text-center">
          {{ $t('views.link_business_profile.welcome', {name: userData.getEmail()}) }}
        </h2>
        <p class="LinkAccount__text">
          {{ $t('views.link_business_profile.text') }}
        </p>
      </template>

      <template slot="footer">
        <ui-button
          :face="FACES.text"
          :size="SIZES.large"
          @clickbutton="onClickDontLinkProfile"
        >
          {{ $t('views.link_business_profile.dont_link') }}
        </ui-button>

        <ui-button
          :size="SIZES.large"
          pill
          @clickbutton="onClickLinkProfile"
        >
          {{ $t('views.link_business_profile.link_accounts') }}
        </ui-button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { navigationErrorHandler } from '@emobg/web-utils';
import Modal from '@/templates/Modal/Modal';
import EventHandlerMixin from '@/mixins/EventHandler';
import LoaderMixin from '@/mixins/Loader';
import UserDataMixin from '@/mixins/UserData';
import NotificationsMixin from '@/mixins/Notifications';
import BookingRoutesNames from '@Bookings/router/routes-names';
import { useAuth } from '@/composable/Api/Auth/auth';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import NotificationListManager from '@/components/NotificationList/NotificationListManager';
export default {
  name: 'LinkBusinessProfile',
  components: {
    Modal,
    NotificationListManager,
  },

  mixins: [
    EventHandlerMixin,
    LoaderMixin,
    UserDataMixin,
    NotificationsMixin,
  ],

  setup() {
    const { idpUuid } = useAuth();
    const { notifyError } = useNotifications();
    return {
      idpUuid,
      notifyError,
    };
  },

  created() {
    this.toggleLoaderStatus(false);
  },

  methods: {
    onClickDontLinkProfile() {
      this.$router.push({ name: BookingRoutesNames.home }).catch(navigationErrorHandler);
    },

    onClickLinkProfile() {
      this.setFromIDPSignUp(this.idpUuid);

      const invitationToken = this.$cookie.get('invitationToken');
      const invitationUUID = this.$cookie.get('invitationUUID');

      if (!(invitationToken && invitationUUID)) {
        this.notifyError({
          text: this.$t('notifications.whooops'),
          textAction: 'Ok',
        });
        this.$router.replace({ name: 'SignUp' }).catch(navigationErrorHandler);
      }

      this.$cookie.delete('invitationToken');
      this.$cookie.delete('invitationUUID');

      this.$router.replace(
        {
          name: 'CompleteInvitation',
          params: {
            invitationToken,
            invitationUUID,
          },
        },
      ).catch(navigationErrorHandler);
    },
  },
};

</script>
