import { mapActions, mapGetters } from 'vuex';
import { nameSpace as modalNameSpace } from '@/vue/stores/Modal/ModalStore';

export default {

  /**
   * Modal params can be send like setModalComponent(component,
   * { location_name: 'Test' }, { vehicle_model : 'Testable'});
   * These params are joined with ES6 expression: ...params. (See method setModalComponent)
   * To get params such as individual variables, you can do:
   * const [location, vehicle] = getModalParams()
   */

  computed: {

    ...mapGetters(modalNameSpace, {
      modalComponentShowed: 'getComponent',
      isModalComponent: 'isSameComponent',
      modalParams: 'getModalParams',
    }),

  },

  methods: {

    ...mapActions(modalNameSpace, {
      openModalComponent: 'open',
      closeModalComponent: 'close',
    }),

    ...mapGetters(modalNameSpace, {
      getModalComponent: 'getComponent',
      isModalOpened: 'isOpened',
      getModalParams: 'getModalParams',
    }),

    setModalComponent(component, ...params) {
      this.$store.dispatch(`${modalNameSpace}/setComponent`, {
        component,
        params,
      });
    },

  },

};
