<template>
  <div data-test-id="link_admin_account">
    <NotificationListManager />

    <FeedbackModalComponent
      v-modal="linkAccountModal.isOpen"
      v-bind="linkAccountModal"
      is-closable
      @close-modal="navigateDontLink"
    />
  </div>
</template>

<script setup>
// TODO: https://europcarmobility.atlassian.net/browse/CF-186
import { COOKIE_NAMES, navigationErrorHandler } from '@emobg/web-utils';
import Cookie from 'js-cookie';
import get from 'lodash/get';
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router/composables';
import { useStore } from 'vuex-composition-helpers/dist';

import { useAuth } from '@/composable/Api/Auth/auth';
import { useTranslations } from '@/composable/App/Translations';
import { userData as userDataGetter } from '@/stores/User/UserData/UserDataMapper';
import COMPANY_SIGN_UP_ROUTES from '@Account/SignUp/router/routes-names';
import { MUTATIONS } from '@/vue/refactor/core/store/company/mutations';
import { FULL_COMPANY_NAMESPACE } from '@/vue/refactor/core/store/company/state';
import NotificationListManager from '@/components/NotificationList/NotificationListManager';

import { ACTIONS, nameSpace as LOADER_NAMESPACE } from '@Shared/store/Loader/LoaderStore';
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';

const { idpUuid } = useAuth();
const router = useRouter();
const store = useStore();
const { $t } = useTranslations();

const userData = computed(userDataGetter);

const setLoaderStatus = newStatus => store.dispatch(`${LOADER_NAMESPACE}/${ACTIONS.setLoaderStatus}`, newStatus);

const isLinkAccount = isAccountLinked => store.commit(`${FULL_COMPANY_NAMESPACE}/${MUTATIONS.isLinkAccount}`, isAccountLinked);
const linkAccountUser = userToLink => store.commit(`${FULL_COMPANY_NAMESPACE}/${MUTATIONS.linkAccountUser}`, userToLink);
const linkAccountIdpUuid = idpUuidToLink => store.commit(`${FULL_COMPANY_NAMESPACE}/${MUTATIONS.linkAccountIdpUuid}`, idpUuidToLink);

setLoaderStatus(false);

const navigateDontLink = () => {
  isLinkAccount(false);
  router.push({ name: COMPANY_SIGN_UP_ROUTES.step1 }).catch(navigationErrorHandler);
};

const linkAccountModal = {
  description: $t('views.link_admin_account.welcome', { name: get(userData, 'value.email', '') }),
  extraInfo: $t('views.link_admin_account.text'),
  isOpen: true,
  primaryCallToAction: () => {
    isLinkAccount(true);
    linkAccountIdpUuid(idpUuid);
    linkAccountUser(userData.value);
    router.push({ name: COMPANY_SIGN_UP_ROUTES.step1 }).catch(navigationErrorHandler);
  },
  primaryCallToActionText: $t('views.link_admin_account.link_accounts'),
  secondaryCallToAction: navigateDontLink,
  secondaryCallToActionText: $t('views.link_admin_account.dont_link'),
  title: $t('views.link_admin_account.title'),
};

onMounted(() => {
  Cookie.set(COOKIE_NAMES.returnUrl, '');
});
</script>
