import { mapActions, mapGetters, mapState } from 'vuex';
import { customMapActions } from '@/vue/stores/Store';
import * as UserDataModule from '@/vue/stores/UserData/UserDataStore';

export const {
  allowsPersonalUseOfOpen,
  fetchUserReferralInfo,
  referralInfo,
  isActivatedUser,
  sendReferralInvitations,
  userData,
  fetchUserDetails,
  doesCompanyOwnsData,
  userCompanyUuid,
  userUuid,
  userLocale,
  userCompany,
  hasCompanyAccessToOpenFleet,
  canEmployeeChangeBusinessPaymentMethod,
  updateDirectMarketing,
  updateUserPersonalData,
  parentCSOperatorUuid,
  dedicatedFleetCSOperatorUuid,
  hasDedicatedFleet,
  profilePermissions,
  circleManagerPermissions,
  userFullName,
  trackingId,
  userCompanyName,
  userCountry,
  userHasCompany,
  companyPays,
  companyAllowsCarpooling,
  canChangeBusinessProfilePaymentMethod,
  canChangeCostAllocation,
} = {
  ...mapState(UserDataModule.nameSpace, {
    referralInfo: state => state.referralInfo,
    circleManagerPermissions: state => state.circleManagerPermissions,
  }),

  ...mapActions(UserDataModule.nameSpace, {
    fetchUserReferralInfo: UserDataModule.ACTIONS.fetchUserReferralInfo,
    sendReferralInvitations: UserDataModule.ACTIONS.sendReferralInvitations,
    updateDirectMarketing: UserDataModule.ACTIONS.UPDATE_DIRECT_MARKETING,
    updateUserPersonalData: UserDataModule.ACTIONS.UPDATE_USER_PERSONAL_DATA,
  }),

  ...customMapActions(UserDataModule.nameSpace, {
    fetchUserDetails: UserDataModule.ACTIONS.FETCH_USER_DATA,
  }),

  ...mapGetters(UserDataModule.nameSpace, {
    allowsPersonalUseOfOpen: UserDataModule.GETTERS.allowsPersonalUseOfOpen,
    isActivatedUser: UserDataModule.GETTERS.isActivatedUser,
    userData: UserDataModule.GETTERS.userData,
    doesCompanyOwnsData: UserDataModule.GETTERS.doesCompanyOwnsData,
    userCompanyUuid: UserDataModule.GETTERS.userCompanyUuid,
    userUuid: UserDataModule.GETTERS.userUuid,
    userLocale: UserDataModule.GETTERS.userLocale,
    userCompany: UserDataModule.GETTERS.userCompany,
    userHasCompany: UserDataModule.GETTERS.userHasCompany,
    hasCompanyAccessToOpenFleet:
      UserDataModule.GETTERS.hasCompanyAccessToOpenFleet,
    canEmployeeChangeBusinessPaymentMethod:
      UserDataModule.GETTERS.canEmployeeChangeBusinessPaymentMethod,
    parentCSOperatorUuid: UserDataModule.GETTERS.parentCSOperatorUuid,
    dedicatedFleetCSOperatorUuid:
      UserDataModule.GETTERS.dedicatedFleetCSOperatorUuid,
    hasDedicatedFleet: UserDataModule.GETTERS.hasDedicatedFleet,
    profilePermissions: UserDataModule.GETTERS.profilePermissions,
    userFullName: UserDataModule.GETTERS.userFullName,
    trackingId: UserDataModule.GETTERS.trackingId,
    userCompanyName: UserDataModule.GETTERS.userCompanyName,
    userCountry: UserDataModule.GETTERS.userCountry,
    companyPays: UserDataModule.GETTERS.companyPays,
    companyAllowsCarpooling: UserDataModule.GETTERS.companyAllowsCarpooling,
    canChangeBusinessProfilePaymentMethod:
      UserDataModule.GETTERS.canChangeBusinessProfilePaymentMethod,
    canChangeCostAllocation: UserDataModule.GETTERS.canChangeCostAllocation,
  }),
};
